<template>
<!-- 首页 -->
  <div>
    <!-- 卡片展示区域 -->
    <el-row :gutter="20"  style="padding-left: 15px;padding-top: 10px;padding-right: 15px;margin: 0">
      <el-col :span="8" style="cursor:pointer;" >
        <div class="grid-content bg-purple" @click="toOrder(7)">
          <img src="../../../assets/settlement.png" style="width:56px;float:left;padding:30px 20px 0 40px">
          <div style="padding-top:20px;font-size:26px">{{viewData.monthSettledPrice}}</div>
          <div style="padding-top:8px;font-size:22px;">本月已结算金额</div>
        </div>
      </el-col>
      <el-col :span="8" style="cursor:pointer;" >
        <div class="grid-content bg-purple" @click="toOrder(7)">
          <img src="../../../assets/settlement.png" style="width:56px;float:left;padding:30px 20px 0 40px">
          <div style="padding-top:20px;font-size:26px">{{viewData.settledPrice}}</div>
          <div style="padding-top:8px;font-size:22px;">已结算金额</div>
        </div>
      </el-col>
      <el-col :span="8" style="cursor:pointer;" >
        <div class="grid-content bg-purple" @click="toOrder(7)">
          <img src="../../../assets/order.png" style="width:56px;float:left;padding:30px 20px 0 40px">
          <div style="padding-top:20px;font-size:26px;">{{viewData.settledCount}}</div>
          <div style="padding-top:8px;font-size:22px;">已结算订单数</div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="padding-left: 15px;padding-top: 10px;padding-right: 15px;margin: 0">
      <el-col :span="8" style="cursor:pointer;" >
        <div class="grid-content bg-purple" @click="toOrder(100)">
          <img src="../../../assets/equipment.png" style="width:56px;float:left;padding:30px 20px 0 40px">
          <div style="padding-top:20px;font-size:26px;">{{viewData.dayDeviceCount}}</div>
          <div style="padding-top:8px;font-size:22px;">今日新增设备数</div>
        </div>
      </el-col>
      <el-col :span="8" style="cursor:pointer;" >
        <div class="grid-content bg-purple" @click="toOrder(100)">
          <img src="../../../assets/stock.png" style="width:66px;float:left;padding:25px 15px 0 35px">
          <div style="padding-top:20px;font-size:26px;">{{viewData.deviceCount}}</div>
          <div style="padding-top:8px;font-size:22px;">设备库存数</div>
        </div>
      </el-col>
      <el-col :span="8" style="cursor:pointer;">
        <div class="grid-content bg-purple"  @click="toOrder(6)">
          <img src="../../../assets/noOrder.png" style="width:56px;float:left;padding:30px 20px 0 40px">
          <div style="padding-top:20px;font-size:26px;">{{viewData.beSettledCount}}</div>
          <div style="padding-top:8px;font-size:22px;">待结算订单数</div>
        </div>
      </el-col>

    </el-row>
    <!-- 表格展示区域 -->
    <div style="padding-left: 25px;padding-right: 25px;padding-top: 10px">
      <div class="body_style_userList" v-show="authorization('/home-data-manage')">
        <h4 style="margin:0;margin-bottom:10px">数据展示</h4>
        <el-form :inline="true" style="margin-top:5px">
          <el-form-item label="姓名">
            <el-input v-model="params.masterName" placeholder="请输入姓名" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="账号">
            <el-input v-model="params.account" placeholder="请输入账号" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="区域">
            <el-select v-model="params.regionId" placeholder="请选择区域" size="mini">
              <el-option v-for="item in regionList" :key="item.id" :label="item.regionName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <el-col :span="11">
              <el-date-picker type="datetime" v-model="params.startTime" value-format="timestamp"  placeholder="开始时间" style="width: 100%;" size="mini"></el-date-picker>
            </el-col>
            <el-col class="line" :span="2" style="text-align: center">--</el-col>
            <el-col :span="11">
              <el-date-picker type="datetime" v-model="params.endTime" value-format="timestamp" placeholder="结束时间"  style="width: 100%;" size="mini"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="checkData" size="mini">查询</el-button>
            <el-button type="info" @click="reset" size="mini">重置</el-button>
          </el-form-item>
        </el-form>

        <el-table size="mini" :data="tableData" stripe
          :header-cell-style="{ background: 'rgba(132,170,206,0.29)',color: '#343333'}"
          height="350px"
          :summary-method="getSummaries"
          show-summary
        >
          <el-table-column prop="account" label="账号" width="100px"></el-table-column>
          <el-table-column prop="userName" label="姓名" width="120px"></el-table-column>
          <el-table-column prop="regionName" label="所属区域" ></el-table-column>
<!--          <el-table-column prop="beSettledPrice" label="待结算金额"></el-table-column>-->
          <el-table-column prop="settledPrice" label="已结算金额"></el-table-column>

          <!--        <el-table-column prop="beLeaflets" label="待派单数量"></el-table-column>-->
          <el-table-column prop="beFirstCount" label="待首联数"></el-table-column>
          <el-table-column prop="meterCount" label="电表申请中数"></el-table-column>
          <el-table-column prop="beInstallCount" label="待安装数"></el-table-column>
          <el-table-column prop="installCount" label="已安装数"></el-table-column>
          <el-table-column prop="rejectedCount" label="被驳回数"></el-table-column>
          <el-table-column prop="noInstallCount" label="暂不安装数"></el-table-column>


          <el-table-column prop="orderTotalCount" label="订单总数"></el-table-column>
          <el-table-column prop="deviceTotalCount" label="设备总数"></el-table-column>
          <el-table-column prop="deviceCount" label="设备库存数"></el-table-column>
          <el-table-column prop="receivingOrder" label="接单状态" :formatter="receivingOrderFormat"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="food_style_userList" >
          <el-pagination background :page-sizes="sizes" layout="total,sizes,prev, pager, next" :total="total" @current-change="handleCurrentChange" @size-change="sizeChange"></el-pagination>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { authorization } from '../../../authorization/authorization';
export default {
  data() {
    return {
      viewData:[],
      tableData:[],
      regionList:[],
      params: {
        page: 1,
        size: 10,
        startTime:'',
        endTime:'',
        masterName:'',
        regionId:'',
        account:''
      },
      sizes: [10, 15,-1],
      total:0,
    };
  },
  created() {
    this.queryRegionList();
    this.queryHomeList()
  },
  methods: {
    authorization,
    queryRegionList(){//获取区域下拉数据
      this.axios({
        method: "get",
        url: "/v1/web/region-manage/select-region-drop-down",
        params: this.params,
      }).then((res) => {
        this.regionList = []
        let obj = {}
        obj.id = 0
        obj.regionName="全部"
        this.regionList.push(obj)
        this.regionList.push(...res.data.data.list);
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if(index === 1 || index === 2 || index === 14){
          sums[index] = ' ';
        }else {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if(index === 3){
            sums[index] += ' 元';
          }else if(index === 13){
            sums[index] = '';
          } else {
            sums[index] += ' 单';
          }

        }
      });
      return sums;
    },
    reset() {
      this.params = {
        page: 1,
        size: 10,
        startTime:'',
        endTime:'',
        masterName:'',
        regionId:'',
        account:''
      };
      this.queryHomeList();
    },
    checkData(){
      if(this.params.startTime !== ''){
        this.params.startTime = new Date(this.params.startTime).getTime();
      }
      if(this.params.endTime !== ''){
        this.params.endTime = new Date(this.params.endTime).getTime()
      }
      this.queryHomeList()
    },
    queryHomeList(){//获取首页数据
      this.axios({
        method: "post",
        url: "/v1/web/home/get-home-card",
        params: this.params,
      }).then((res) => {
        this.viewData = res.data.data;
        this.tableData = res.data.data.homeDataListVOList;
        this.total = res.data.data.total;
      });
    },
    receivingOrderFormat: function (row) {
      if (row.receivingOrder === 0) {
        return "正常接单";
      } else if (row.receivingOrder === 1) {
        return "暂不接单";
      }
    },
    handleCurrentChange(val){
      this.params.page = val;
      this.queryHomeList();
    },
    sizeChange(val) {
      this.params.size = val;
      this.params.page = 1;
      this.queryHomeList();
    },
    toOrder(index){
      if(index == 7 || index == 6){
        this.$router.push({path:"/home-admin/order/order-manage",query:{activeName:index}})
      }else {
        this.$router.push({path:"/home-admin/device/device-manage"})
      }

    }
  },
};
</script>

<style scoped>
.el-table::before {
  height: 0;
}
.bg-purple {
    background: #fff;
  }
.grid-content {
  border-radius: 10px;
  min-height: 120px;
}
.body_style_userList {
  height: 550px;
  overflow: auto;
  background-color: white;
  border-radius: 10px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.food_style_userList {
  background-color: white;
  border-radius: 10px;
  text-align: right;
  margin-top: 50px;
  padding-bottom: 20px;
  padding-right: 20px;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

.el-table {
  overflow: visible !important;
}
</style>